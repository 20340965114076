import logo from "./Speed1.gif";
import logo1 from "./logoRed.svg";
import "./App.css";
import {useEffect, useState} from "react";
import IntroLoader from "./IntroLoader";

function App() {

    const [intro, setIntro] = useState(true);

    useEffect(() => {
        setTimeout(() => setIntro(false), 3200);
    }, []);
  return (
      <>
          {intro ?
          <IntroLoader /> :
    <div
      style={{
        backgroundColor: "white",
        textAlign: "center",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <img src={logo1} alt="" style={{ marginTop: "50px" }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={logo} alt="" style={{ width: "450px" }} />
      </div>
      <h5 className="animate">Aus der Schweiz für die Schweiz.</h5>
      <h6 className="desc">
        Hier entsteht eine neue Immobilienplattform für den Schweizer
        Immobilienmarkt
      </h6>
    </div>
                }
      </>
  );
}

export default App;

import React from "react";
import Gif from './gif.mp4';


function IntroLoader() {
    return (
        <div
            style={{
                backgroundColor: 'white',
                textAlign: 'center',
                height: '100vh',
                overflow: 'hidden',
                background: 'transparent',
                mixBlendMode: 'multiply',
            }}
        >
            <video
                playsInline
                autoPlay
                muted
                style={{ height: '100%', width: '100%', background: 'white', backgroundColor: 'transparent', mixBlendMode: 'color-burn' }}
                preload={'none'}
            >
                {/* <source src='https://d2z63oo0n3zckk.cloudfront.net/banner_animated_compressed.mp4' />*/}
                <source src={Gif} />
            </video>
            {/* <img src={Gif} alt='' style={{ width: '420px', marginTop: '25px' }} />*/}
        </div>
    )
}

export default IntroLoader;
